import { useSelector } from 'react-redux';

import {
  getConfig,
  getEnvironment,
  getLocalizer,
  getResource,
} from 'ducks/config';

export const useConfig = () => useSelector(getConfig);

export const useEnv = () => useSelector(getEnvironment);

export const useResource = () => useSelector(getResource);

export const useLocalizer = () => useSelector(getLocalizer);
