import { Theme } from '@material-ui/core/styles';
import {
  CreateCSSProperties,
  CSSProperties,
} from '@material-ui/styles/withStyles';
import { TablePaginationClassKey } from '@material-ui/core/TablePagination';

const muiTablePagination = (
  theme: Theme,
):
  | Partial<
      Record<
        TablePaginationClassKey,
        | CSSProperties
        | CreateCSSProperties
        | ((props: Record<string, unknown>) => CreateCSSProperties)
      >
    >
  | undefined => ({
  root: {
    '& .MuiSelect-icon': {
      color: theme.palette.primary.main,
    },
    '& .MuiTablePagination-toolbar': {
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: 0,
      },
    },
    '& .MuiTablePagination-caption': {
      color: theme.palette.primary[800],
    },
    '& .MuiInputBase-root + p': {
      color: theme.palette.secondary.main,
    },
    '& .MuiSelect-root': {
      paddingLeft: 0,
    },
    '& .MuiButtonBase-root': {
      padding: theme.spacing(1),
    },
    '& .MuiTablePagination-selectRoot': {
      [theme.breakpoints.down('xs')]: {
        marginRight: theme.spacing(2),
      },
    },
  },
});

export default muiTablePagination;
