import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { CircularProgressProps } from '@material-ui/core/CircularProgress';
import clsx from 'clsx';

const useStyles = makeStyles(
  {
    loading: {
      alignItems: 'center',
      display: 'flex',
      height: '100%',
      flex: 1,
      justifyContent: 'center',
      width: '100%',
    },
  },
  { name: 'Loading' },
);

interface ILoadingProps {
  className?: string;
  progressProps?: CircularProgressProps;
}

const Loading: React.FC<ILoadingProps> = ({ className, progressProps }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.loading, className)}>
      <CircularProgress {...progressProps} />
    </div>
  );
};

export default Loading;
