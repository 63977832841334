import createTheme from '@material-ui/core/styles/createTheme';

import { options } from '../../theme';
import muiButton, { muiButtonProps } from './overrides/muiButton';
import muiButtonGroup from './overrides/muiButtonGroup';
import muiCheckbox from './overrides/muiCheckbox';
import muiIconButton, { muiIconButtonProps } from './overrides/muiIconButton';
import muiPaper, { muiPaperProps } from './overrides/muiPaper';
import muiSelect from './overrides/muiSelect';
import muiSkeleton, { muiSkeletonProps } from './overrides/muiSkeleton';
import muiSwitch from './overrides/muiSwitch';
import muiTable from './overrides/muiTable';
import muiTablePagination from './overrides/muiTablePagination';
import muiTextField from './overrides/muiTextField';
import muiTooltip from './overrides/muiTooltip';
import muiAutocomplete from './overrides/autocomplete';

const theme = createTheme(options);
const { pxToRem } = theme.typography;

theme.typography = {
  ...theme.typography,
  h1: {
    ...theme.typography.h1,
    [theme.breakpoints.down('xs')]: {
      fontSize: pxToRem(20),
    },
  },
  h2: {
    ...theme.typography.h2,
    [theme.breakpoints.down('xs')]: {
      fontSize: pxToRem(16),
    },
  },
};
theme.props = {
  ...theme.props,
  MuiButtonBase: {
    disableRipple: true,
  },
  MuiButton: muiButtonProps,
  MuiIconButton: muiIconButtonProps,
  MuiPaper: muiPaperProps,
  // @ts-ignore missing in material-ui prop types
  MuiSkeleton: muiSkeletonProps,
};
theme.overrides = {
  // @ts-ignore missing in material-ui override types
  MuiPickersBasePicker: {
    pickerView: {
      minHeight: 370,
      justifyContent: 'flex-start',
    },
  },
  MuiInputLabel: {
    formControl: {
      position: 'relative',
      transform: 'none',
      color: theme.palette.text.primary,
    },
  },
  MuiTab: {
    root: {
      borderBottom: `2px solid ${theme.palette.text.hint}`,
      minWidth: 'auto',
      textTransform: 'none',
    },
    textColorInherit: { opacity: 0.25 },
  },
  MuiListItem: {
    button: {
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary[300],
        '&:hover': {
          backgroundColor: theme.palette.primary[100],
        },
      },
      '&:hover': {
        backgroundColor: theme.palette.primary[100],
      },
    },
  },
  // @todo use Atom Chip, remove this
  MuiChip: {
    root: {
      backgroundColor: theme.palette.tertiary.main,
      color: '#ffffff',
    },
  },
  MuiMenuItem: {
    root: {
      '& *:focus': {
        outline: 'none',
      },
    },
  },
  MuiButton: muiButton(theme),
  MuiButtonGroup: muiButtonGroup(theme),
  MuiCheckbox: muiCheckbox(theme),
  MuiIconButton: muiIconButton(theme),
  MuiPaper: muiPaper(theme),
  MuiSelect: muiSelect(theme),
  MuiSkeleton: muiSkeleton(theme),
  MuiSwitch: muiSwitch(theme),
  MuiTable: muiTable(theme),
  MuiTablePagination: muiTablePagination(theme),
  MuiTextField: muiTextField(theme),
  MuiTooltip: muiTooltip(theme),
  MuiAutocomplete: muiAutocomplete(theme),
};

export default theme;
