import auth, { reducerKey as authKey } from './ducks/auth';
import config, { reducerKey as configKey } from './ducks/config';
import notification, {
  reducerKey as notificationKey,
} from './ducks/notification';
import settings, { reducerKey as settingsKey } from './ducks/settings';
import user, { reducerKey as userKey } from './ducks/user';

export default {
  [authKey]: auth,
  [configKey]: config,
  [notificationKey]: notification,
  [settingsKey]: settings,
  [userKey]: user,
};
