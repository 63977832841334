import * as React from 'react';
import { Container, ContainerProps, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(
  ({ breakpoints, spacing }) => ({
    center: {
      height: '100%',
      flex: '1 1 auto',
      [breakpoints.down('md')]: {
        height: 'auto',
      },

      '@supports (padding: max(0px))': {
        paddingLeft: `max(${spacing(2)}px, env(safe-area-inset-left))`,
        paddingRight: `max(${spacing(2)}px, env(safe-area-inset-right))`,
        paddingTop: `max(${spacing(2)}px, env(safe-area-inset-top))`,
        paddingBottom: `max(${spacing(2)}px, env(safe-area-inset-bottom))`,

        [breakpoints.up('sm')]: {
          paddingLeft: `max(${spacing(3)}px, env(safe-area-inset-left))`,
          paddingRight: `max(${spacing(3)}px, env(safe-area-inset-right))`,
          paddingTop: `max(${spacing(3)}px, env(safe-area-inset-top))`,
          paddingBottom: `max(${spacing(3)}px, env(safe-area-inset-bottom))`,
        },
      },
    },
  }),
  { name: 'CenterContainer' },
);

const CenterContainer: React.FC<ContainerProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <Container
      className={clsx(classes.center, className)}
      maxWidth={false}
      {...rest}
    />
  );
};

export default CenterContainer;
