import { flow, get } from 'lodash/fp';

export const reducerKey = 'config';

export const getConfig = get(reducerKey);
export const getEnvironment = get([reducerKey, 'environment']);
export const getApi = get([reducerKey, 'api']);
export const getResource = get([reducerKey, 'resource']);
export const getLocalizer = get([reducerKey, 'resource', 'localizer']);

export const getUriApi = flow(getApi, get('aragog'));
export const getCdnUriApi = flow(getApi, get('aragogCdn'));

export const getUriSubscriptions = flow(getApi, get('subscriptions'));

export default (state = null) => state;
export const getPiwikUrl = get([reducerKey, 'piwikUrl']);
export const getPiwikId = get([reducerKey, 'piwikId']);

export const getHotjarId = get([reducerKey, 'hotjarId']);
export const getHotjarSnippetVersion = get([
  reducerKey,
  'hotjarSnippetVersion',
]);
