import { useEffect } from 'react';
import PropTypes from 'prop-types';

const HeadTitle = ({ children }) => {
  useEffect(() => {
    document.title = children;
  }, [children]);

  return null;
};

HeadTitle.propTypes = {
  children: PropTypes.string.isRequired,
};

HeadTitle.displayName = 'HeadTitle';

export default HeadTitle;
