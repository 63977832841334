import axios from 'axios';

// TODO: quick fix for passing UnitTests, find better solutions
const publicPath =
  // eslint-disable-next-line camelcase
  typeof __webpack_public_path__ !== 'undefined'
    ? // eslint-disable-next-line camelcase,no-undef
      __webpack_public_path__
    : './';

export const fetchConfig = axios.get(`${publicPath}config.json`, {
  params: { cache: CACHE },
});

const getConfig = async () => {
  let config;

  await fetchConfig.then(({ data }) => {
    config = data;
  });

  return config;
};

export default getConfig;
