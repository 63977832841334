import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Notification from '../../Notification';
import {
  getNotification,
  resetNotification,
} from '../../../ducks/notification';

const AppNotification = () => {
  const dispatch = useDispatch();
  const notificationProp = useSelector(getNotification);
  const [notification, setNotification] = useState(notificationProp);

  const onClose = () => dispatch(resetNotification());

  useLayoutEffect(() => {
    if (notificationProp) {
      setNotification(notificationProp);
    }
  }, [notificationProp]);

  return (
    <Notification
      {...notification}
      onClose={onClose}
      open={Boolean(notificationProp)}
    />
  );
};

export default AppNotification;
