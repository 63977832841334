import React from 'react';
import {
  DefaultToast,
  DefaultToastContainer,
  ToastContainerProps,
  ToastProps,
  ToastProvider as ToastProviderNotifications,
} from 'react-toast-notifications';
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import Success from '../molecules/ToastNotifications/Success';
import Error from '../molecules/ToastNotifications/Error';
import Warning from '../molecules/ToastNotifications/Warning';
import Info from '../molecules/ToastNotifications/Info';

import './styles.css';

const SUCCESS = 'success';
const ERROR = 'error';
const WARNING = 'warning';
const INFO = 'info';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    '& > div': {
      height: 'auto !important',
    },
    '& div.react-toast-notifications__toast': {
      backgroundColor: 'transparent !important',
      boxShadow: 'none',
      minWidth: 255,
      maxWidth: 300,
      marginBottom: 0,
    },
    '& div.react-toast-notifications__toast__icon-wrapper, & div.react-toast-notifications__toast__dismiss-button':
      {
        display: 'none',
      },
    '& div.react-toast-notifications__toast__content': {
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: theme.spacing(0.5),
    },
  },
}));

interface ICustomChildren {
  // eslint-disable-next-line react/no-unused-prop-types
  children: {
    title?: string;
    description: string;
  };
  // eslint-disable-next-line react/require-default-props
  loading?: boolean;
}

const ToastContainer = (props: ToastContainerProps) => (
  <span style={{ zIndex: 1999, marginTop: 71 }}>
    <DefaultToastContainer {...props} />
  </span>
);

const chooseAppearance = ({
  appearance,
  children,
  ...props
}: Omit<ToastProps, 'children'> & ICustomChildren) => {
  switch (appearance) {
    case SUCCESS:
      return (
        <DefaultToast {...props} appearance="success">
          <Success>{children}</Success>
        </DefaultToast>
      );
    case ERROR:
      return (
        <DefaultToast {...props} appearance="error">
          <Error>{children}</Error>
        </DefaultToast>
      );
    case WARNING:
      return (
        <DefaultToast {...props} appearance="warning">
          <Warning>{children}</Warning>
        </DefaultToast>
      );
    case INFO:
    default:
      return (
        <DefaultToast {...props} appearance="info">
          <Info>{children}</Info>
        </DefaultToast>
      );
  }
};

const MyCustomToasts = ({
  loading,
  ...props
}: ToastProps & ICustomChildren) => {
  const classes = useStyles();
  const { appearance } = props;

  return (
    <Box
      className={clsx(classes.wrapper, {
        loadingToast: loading,
        [appearance]: true,
      })}
    >
      {chooseAppearance(props)}
    </Box>
  );
};

const ToastProvider: React.FC = ({ children }) => (
  <ToastProviderNotifications
    autoDismiss
    components={{ ToastContainer, Toast: MyCustomToasts }}
  >
    {children}
  </ToastProviderNotifications>
);

export default ToastProvider;
