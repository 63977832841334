import { Feature } from 'react-enable';

export const SENTRYC_GMBH = 'Sentryc GmbH';
export const PAGINATION_MAX_SIZE = 100;
export const PAGINATION_DEFAULT_SIZE = 20;
export const PAGINATION_DEFAULT_PAGE = 0;
export const SELLER_NAVIGATION_LIMIT = 3;

export const PRODUCER = 'PRODUCER';
export const EXTERNAL_USER = 'EXTERNAL_USER';
export const DEFAULT_CURRENCY_SIGN = '$';

export const ALL = 'ALL';

export const mainId = 'main_Admin';

export const EMAIL = {
  Support: 'support@sentryc.com',
};

export const ROLES = { PRODUCER, EXTERNAL_USER };
export const ROLES_ALLOWED = [ROLES.PRODUCER];

export const FEATURES = {
  UserCollaboration: 'user-collaboration',
};
export const FEATURES_ALL: Feature[] = [
  {
    name: FEATURES.UserCollaboration,
  },
];
export const FEATURES_ENABLED: string[] = [FEATURES.UserCollaboration];
