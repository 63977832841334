import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'svgxuse/svgxuse.min';
import React from 'react';
import { render } from 'react-dom';
import './polyfills';

import { setI18N } from './i18n';
import Root from './components/Root';

const renderAsync = async () => {
  await setI18N();
  render(<Root />, document.getElementById('root'));
};

renderAsync();
