/* eslint-disable func-names */
// https://gist.github.com/MonsieurV/fb640c29084c171b4444184858a91bc7
/*
 * Safari and Edge polyfill for createImageBitmap
 * https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/createImageBitmap
 *
 * Support source image types Blob and ImageData.
 *
 * From: https://dev.to/nektro/createimagebitmap-polyfill-for-safari-and-edge-228
 * Updated by Yoan Tournade <yoan@ytotech.com>
 */

if (!('createImageBitmap' in window)) {
  window.createImageBitmap = async function (data) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      let dataURL;
      if (data instanceof Blob) {
        dataURL = URL.createObjectURL(data);
      } else if (data instanceof ImageData) {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = data.width;
        canvas.height = data.height;
        ctx.putImageData(data, 0, 0);
        dataURL = canvas.toDataURL();
      } else {
        throw new Error(
          'createImageBitmap does not handle the provided image source type',
        );
      }
      const img = document.createElement('img');
      img.addEventListener('load', function () {
        resolve(this);
      });
      img.src = dataURL;
    });
  };
}

// Polyfill for .toBlob
if (!HTMLCanvasElement.prototype.toBlob) {
  Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
    value(callback, type, quality) {
      const dataURL = this.toDataURL(type, quality).split(',')[1];
      setTimeout(() => {
        const binStr = atob(dataURL);
        const len = binStr.length;
        const arr = new Uint8Array(len);

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < len; i++) {
          arr[i] = binStr.charCodeAt(i);
        }

        callback(new Blob([arr], { type: type || 'image/png' }));
      });
    },
  });
}
