import { Theme } from '@material-ui/core/styles';
import {
  CreateCSSProperties,
  CSSProperties,
} from '@material-ui/styles/withStyles';
import { ButtonGroupClassKey } from '@material-ui/core/ButtonGroup';

const muiButtonGroup = ({
  palette,
}: Theme):
  | Partial<
      Record<
        ButtonGroupClassKey,
        | CSSProperties
        | CreateCSSProperties
        | ((props: Record<string, unknown>) => CreateCSSProperties)
      >
    >
  | undefined => ({
  root: {
    /* WORKAROUND: For IOS Safari SVG CSS Bug */
    '& .MuiButton-outlinedSecondary': {
      color: 'rgba(33, 34, 34, 0.99)',
    },
    '& .MuiButton-outlinedSecondary.Mui-disabled': {
      borderColor: palette.secondary[200],
      cursor: 'not-allowed !important',
    },
  },
});

export default muiButtonGroup;
