import { get } from 'lodash/fp';

export const reducerKey = 'notification';

const RESET = `${reducerKey}/RESET`;
const SET = `${reducerKey}/SET`;

export const resetNotification = () => ({ type: RESET });
export const setError = (message) => ({
  type: SET,
  payload: { message, variant: 'error' },
});
export const setNotification = (message) => ({
  type: SET,
  payload: { message },
});

export const getNotification = get(reducerKey);

const initialState = null;

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case RESET:
      return initialState;

    case SET:
      return payload;

    default:
      return state;
  }
};
