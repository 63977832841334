import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons';
import { AlertTitle } from '@material-ui/lab';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Icon from 'components/atoms/Icon';

import useStylesToastr from './useStylesToastr';

const useStyles = makeStyles((theme) => ({
  ...useStylesToastr({ theme, backgroundColor: theme.palette.success.light }),
}));

interface ISuccess {
  children: {
    title?: string;
    description: string;
  };
}

const Success: React.FC<ISuccess> = ({ children: { title, description } }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Alert
      classes={{
        root: classes.root,
        icon: classes.icon,
        message: classes.message,
      }}
      icon={<Icon icon={faCheckCircle} />}
      severity="success"
      variant="filled"
    >
      <AlertTitle>
        <Typography className={classes.text} variant="h4">
          {title || t('success', 'Success')}
        </Typography>
      </AlertTitle>
      <Typography className={classes.text} variant="h5">
        {description}
      </Typography>
    </Alert>
  );
};

export default Success;
