import {
  CreateCSSProperties,
  CSSProperties,
} from '@material-ui/styles/withStyles';
import { Theme } from '@material-ui/core/styles';
import { SelectClassKey } from '@material-ui/core/Select';

const muiSelect = ({
  palette,
  spacing,
}: Theme):
  | Partial<
      Record<
        SelectClassKey,
        | CSSProperties
        | CreateCSSProperties
        | ((props: Record<string, unknown>) => CreateCSSProperties)
      >
    >
  | undefined => ({
  root: {
    '&.MuiOutlinedInput-input': {
      padding: spacing(1.25, 4, 1.25, 2),
    },
    '&.Mui-disabled': {
      backgroundColor: palette.secondary[50],
      cursor: 'not-allowed !important',
      color: palette.secondary[400],
    },
  },
  select: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    color: palette.primary.main,
    height: 24,
    '&.MuiSelect-iconOutlined': {
      right: spacing(1.5),
      width: 14,
    },
    '&.Mui-disabled': {
      color: palette.secondary[400],
    },
  },
});

export default muiSelect;
