import {
  CreateCSSProperties,
  CSSProperties,
} from '@material-ui/styles/withStyles';
import { Theme } from '@material-ui/core/styles';

const muiTextField = (
  theme: Theme,
):
  | Partial<
      Record<
        'root',
        | CSSProperties
        | CreateCSSProperties
        | ((props: Record<string, unknown>) => CreateCSSProperties)
      >
    >
  | undefined => ({
  root: {
    '& .MuiFormLabel-root': {
      position: 'absolute',
    },
    '& .MuiOutlinedInput-input': {
      height: 40,
      padding: theme.spacing(0, 1.5),
    },
    '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      transform: 'translate(14px, 13px) scale(1)',
      color: theme.palette.secondary[400],
    },
    '& .MuiInputLabel-shrink': {
      color: theme.palette.primary[800],
    },
    '&:hover .MuiOutlinedInput-root:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline':
      {
        boxShadow: theme.customShadows.dropdown,
        borderColor: 'transparent',
      },
    '& .MuiOutlinedInput-notchedOutline': {
      transition:
        'padding-left 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, box-shadow 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
      boxShadow: theme.customShadows.dropdown,
    },
    '& .Mui-error .MuiOutlinedInput-notchedOutline': {
      borderWidth: 1,
      borderColor: `${theme.palette.error.main} !important`,
    },
    '& .MuiInputAdornment-positionStart': {
      marginRight: 0,
    },
    '& .Mui-disabled': {
      backgroundColor: theme.palette.secondary[50],
      cursor: 'not-allowed',
    },
    '& textarea.MuiOutlinedInput-inputMultiline': {
      padding: 0,
    },
  },
});

export default muiTextField;
