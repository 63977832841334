import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import * as ChangeCase from 'change-case';
import { getOr, noop } from 'lodash/fp';
import TagManager from 'react-gtm-module';
import { datadogLogs } from '@datadog/browser-logs';
import type { TFunction } from 'i18next/typescript/t.v4';

import { PRODUCTION } from './constants/config/environment';
import getConfig from './config';

i18n.use(Backend).use(LanguageDetector).use(initReactI18next);

export const setI18N = async () => {
  // @ts-ignore
  const { environment, resource } = await getConfig();

  i18n.init(
    {
      backend: {
        loadPath: `${resource.localizer}/locales/{{lng}}/{{ns}}.json`,
        crossDomain: true,
      },
      interpolation: {
        escapeValue: false,
        format: (value, format) => {
          if (format) {
            // TODO: Custom Format function instead of noop when required
            return getOr(noop, format, ChangeCase)(value);
          }

          return value;
        },
      },
      keySeparator: '.',
      fallbackLng: 'en',
      supportedLngs: ['de', 'en'],
      react: {
        useSuspense: false, // prevent unneeded <Suspense /> trigger (especially <ReduxProvider /> double config loading)
      },
      load: 'languageOnly',
      ns: ['translation', 'countries', 'feedback', 'fallback'],
      defaultNS: 'translation',
      fallbackNS: 'fallback',
      compatibilityJSON: 'v3',
    },
    (error: any, t: TFunction) => {
      if (error) return console.error('I18N', error);

      return false;
    },
  );

  i18n.on('languageChanged', (lng) => {
    const langSeperator = '-';
    const lang2char = lng.includes(langSeperator)
      ? lng.split(langSeperator)[0]
      : lng;
    document.documentElement.setAttribute('lang', lang2char);

    // Log Language change in Google Analytics
    TagManager.dataLayer({
      dataLayer: {
        event: 'Event',
        category: 'Language Change',
        action: lng,
      },
    });

    datadogLogs.logger.info('Language Change', { language: lng });
  });

  // Log Missing Keys for i18n, to ease development & bug fixing
  i18n.on('missingKey', (lngs, namespace, key, res) => {
    const error = {
      message: 'Missing Keys for i18n',
      object: {
        lngs,
        namespace,
        key,
        res,
      },
    };
    if (environment !== PRODUCTION) {
      // eslint-disable-next-line no-console
      console.error(error.message, error.object);
    } else {
      TagManager.dataLayer({
        dataLayer: {
          event: 'Event',
          category: error.message,
          action: JSON.stringify(error.object),
        },
      });

      datadogLogs.logger.info('Error i18n', error);
    }
  });
};

export default i18n;
