import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash/fp';

import { NOT_FOUND } from 'constants/httpStatuses';

import Exception from '../Exception';

import { createInjectStore } from '../../store';
import { history } from '../../sagas';
import { fetchConfig } from '../../config';

let error;
let store;

const ReduxProvider = ({ children }) => {
  const { t } = useTranslation();

  if (error) {
    const status = get(['response', 'status'], error);

    if (status === NOT_FOUND) {
      return <Exception error={t('Config not found')} />;
    }

    return <Exception error={error} />;
  }

  if (!store) {
    throw fetchConfig
      .then(({ data: config }) => {
        store = createInjectStore(history, config);
      })
      .catch((e) => {
        error = e;
      });
  }

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>{children}</ConnectedRouter>
    </Provider>
  );
};

ReduxProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

ReduxProvider.displayName = 'ReduxProvider';

export default ReduxProvider;
