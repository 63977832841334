import { replace } from 'connected-react-router';
import { put, select, takeEvery } from 'redux-saga/effects';

import { createSearch, getQuery } from '../location';

const UPDATE_SEARCH = 'url/UPDATE_SEARCH';

export const updateSearch = (payload) => ({ type: UPDATE_SEARCH, payload });

function* updateSearchSaga({ payload = {} }) {
  const query = yield select(getQuery);

  yield put(
    replace({
      search: createSearch({
        ...query,
        ...payload,
      }),
    }),
  );
}

export function* saga() {
  yield takeEvery(UPDATE_SEARCH, updateSearchSaga);
}
