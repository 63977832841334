import { Theme } from '@material-ui/core/styles';
import {
  CreateCSSProperties,
  CSSProperties,
} from '@material-ui/styles/withStyles';
import { CheckboxClassKey } from '@material-ui/core/Checkbox';

const muiCheckbox = (
  theme: Theme,
):
  | Partial<
      Record<
        CheckboxClassKey,
        | CSSProperties
        | CreateCSSProperties
        | ((props: Record<string, unknown>) => CreateCSSProperties)
      >
    >
  | undefined => ({
  root: {
    padding: 0,
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  disabled: {
    cursor: 'not-allowed !important',
  },
});

export default muiCheckbox;
