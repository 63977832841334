import { Theme } from '@material-ui/core/styles';
import {
  CreateCSSProperties,
  CSSProperties,
} from '@material-ui/styles/withStyles';
import { SwitchClassKey } from '@material-ui/core/Switch';

const baseSize = 20;

const muiCheckbox = (
  theme: Theme,
):
  | Partial<
      Record<
        SwitchClassKey,
        | CSSProperties
        | CreateCSSProperties
        | ((props: Record<string, unknown>) => CreateCSSProperties)
      >
    >
  | undefined => ({
  root: {
    width: 2 * baseSize + 2,
    height: baseSize + 2,
    padding: 0,
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: `translate(${baseSize}px)`,
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: theme.palette.primary.main,
      border: `6px solid ${theme.palette.common.white}`,
    },
  },
  thumb: {
    width: baseSize,
    height: baseSize,
  },
  track: {
    borderRadius: baseSize + 2 / 2,
    border: `1px solid ${theme.palette.secondary[300]}`,
    backgroundColor: theme.palette.secondary[100],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
});

export default muiCheckbox;
