import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Snackbar } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AlertCircle from 'mdi-material-ui/AlertCircle';
import clsx from 'clsx';

const variantIcons = {
  error: AlertCircle,
};

const useStyles = makeStyles(
  (theme) => ({
    message: {
      alignItems: 'center',
      display: 'flex',
    },

    icon: {
      marginRight: theme.spacing(1),
    },

    error: {
      backgroundColor: theme.palette.error.dark,
    },
  }),
  { name: 'Notification' },
);

const Notification = ({ ContentProps, message, variant, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();

  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });

  const Icon = variantIcons[variant];

  return (
    <Snackbar
      anchorOrigin={{
        horizontal: 'center',
        vertical: smUp ? 'top' : 'bottom',
      }}
      ContentProps={{
        ...ContentProps,
        className: clsx(ContentProps.className, classes[variant]),
      }}
      message={
        variant ? (
          <div className={classes.message}>
            <Icon className={classes.icon} />
            {message}
          </div>
        ) : (
          message
        )
      }
      {...rest}
    />
  );
};

Notification.propTypes = {
  ContentProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  message: PropTypes.node,
  variant: PropTypes.oneOf(['error']),
};

Notification.defaultProps = {
  ContentProps: {},
  message: null,
  variant: undefined,
};

export default Notification;
