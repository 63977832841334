import { Theme } from '@material-ui/core/styles';
import {
  CreateCSSProperties,
  CSSProperties,
} from '@material-ui/styles/withStyles';
import { SkeletonClassKey, SkeletonProps } from '@material-ui/lab/Skeleton';

export const muiSkeletonProps: SkeletonProps = {
  animation: 'wave',
};

const muiSkeleton = (
  theme: Theme,
):
  | Partial<
      Record<
        SkeletonClassKey,
        | CSSProperties
        | CreateCSSProperties
        | ((props: Record<string, unknown>) => CreateCSSProperties)
      >
    >
  | undefined => ({
  root: {
    backgroundColor: theme.palette.secondary[100],
  },
});

export default muiSkeleton;
