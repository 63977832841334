import { connectRouter, routerMiddleware } from 'connected-react-router';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import {
  createStateSyncMiddleware,
  initMessageListener,
} from 'redux-state-sync';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import createSagaMiddleware from 'redux-saga';
import { pick, throttle } from 'lodash/fp';

import { setError } from 'ducks/notification';
import {
  reducerKey as authKey,
  LOG_OUT,
  SET_AUTH,
  SET_AUTH_LOGIN,
} from 'ducks/auth';
import { reducerKey as configKey } from 'ducks/config';
import { reducerKey as settingsKey } from 'ducks/settings';

import { loadState, saveState } from './localState';
import rootReducers from './reducers';
import rootSagas from './sagas';

let store;

const onError = (error) => {
  // eslint-disable-next-line no-console
  console.error(error);

  store.dispatch(setError(error.toString()));
};

const sagaMiddleware = createSagaMiddleware({
  onError,
});

const createRootReducer = (reducers, history) =>
  combineReducers({
    ...reducers,
    router: connectRouter(history),
  });

// eslint-disable-next-line import/prefer-default-export
export const createInjectStore = (history, config) => {
  const composeEnhancers = composeWithDevTools({ trace: true });

  const preloadedState = loadState();

  store = createStore(
    createRootReducer(rootReducers, history),
    {
      ...preloadedState,
      [configKey]: config,
    },
    composeEnhancers(
      applyMiddleware(
        sagaMiddleware,
        routerMiddleware(history),
        createStateSyncMiddleware({
          whitelist: [LOG_OUT, SET_AUTH, SET_AUTH_LOGIN],
        }),
      ),
    ),
  );

  store.subscribe(
    throttle(100, () => {
      const state = store.getState();

      saveState(pick([authKey, settingsKey], state));
    }),
  );

  let sagaTask = sagaMiddleware.run(rootSagas);

  if (module.hot) {
    module.hot.accept('./reducers', () =>
      store.replaceReducer(createRootReducer(rootReducers, history)),
    );
    module.hot.accept('./sagas', () => {
      sagaTask.cancel();
      sagaTask.toPromise().then(() => {
        sagaTask = sagaMiddleware.run(rootSagas);
      });
    });
  }

  initMessageListener(store);

  return store;
};
