import { Theme } from '@material-ui/core/styles';
import {
  CreateCSSProperties,
  CSSProperties,
} from '@material-ui/styles/withStyles';
import {
  IconButtonClassKey,
  IconButtonTypeMap,
} from '@material-ui/core/IconButton';
import { OverrideProps } from '@material-ui/core/OverridableComponent';

export const muiIconButtonProps:
  | Partial<
      OverrideProps<
        IconButtonTypeMap<Record<string, unknown>, 'button'>,
        'button'
      >
    >
  | undefined = {
  color: 'primary',
};

const muiIconButton = ({
  palette,
  spacing,
}: Theme):
  | Partial<
      Record<
        IconButtonClassKey,
        | CSSProperties
        | CreateCSSProperties<Record<string, unknown>>
        | ((
            props: Record<string, unknown>,
          ) => CreateCSSProperties<Record<string, unknown>>)
      >
    >
  | undefined => ({
  root: {
    color: palette.text.primary,
    'button&': {
      height: spacing(5),
      transition: 'all .3s',
      width: spacing(5),
    },
    '&.MuiRadio-root': {
      padding: 0,
      '&:hover': {
        backgroundColor: palette.common.white,
        boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.15)',
      },
    },
  },
  sizeSmall: {
    height: spacing(4.5),
    marginLeft: spacing(1),
    width: spacing(4.5),
  },
  colorPrimary: {
    '&[variant="contained"]': {
      backgroundColor: palette.primary.main,
      color: palette.common.white,
      '&.Mui-disabled': {
        backgroundColor: palette.primary.main,
        color: palette.common.white,
        opacity: 0.4,
      },
    },
  },
  colorSecondary: {
    border: `1px solid ${palette.secondary[400]}`,
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: 'transparent !important',
      boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.35)',
      color: palette.secondary.main,
    },
  },
  disabled: {
    borderColor: palette.secondary[200],
  },
});

export default muiIconButton;
