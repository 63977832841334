import { all, select } from 'redux-saga/effects';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import TagManager from 'react-gtm-module';
import { isIOS } from 'react-device-detect';
import { createBrowserHistory } from 'history';
import i18n from 'i18next';

import { DEVELOPMENT } from 'constants/config/environment';
import { getEnvironment } from 'ducks/config';
import { saga as auth } from 'ducks/auth';
import { saga as url } from 'ducks/url';
import { getUser, getUserRoles, saga as user } from 'ducks/user';
import { saga as login } from 'ducks/login';

export const history = createBrowserHistory({
  // eslint-disable-next-line no-undef, camelcase
  basename: __webpack_public_path__,
});

export default function* () {
  const environment = yield select(getEnvironment);
  yield* user();
  const authUser = yield select(getUser);
  const authUserRoles = yield select(getUserRoles);
  const blockedList = ['piwik.pro', 'imagekit.io'];

  if (environment && environment !== DEVELOPMENT) {
    // TODO: Temporary disable DataDog to solve slow iPhone 11 Pro
    if (!isIOS && Services.DataDog) {
      const dataDogConfig = {
        ...Services.DataDog,
        env: environment,
        version: VERSION,
      };
      datadogRum.init({
        ...dataDogConfig,
        beforeSend: (event) => {
          if (event.type === 'resource') {
            return blockedList.some((blocked) =>
              event.resource.url.includes(blocked),
            );
          }

          return true;
        },
      });
      datadogLogs.init({
        ...dataDogConfig,
        beforeSend: (log) => {
          // remove email from view url
          // eslint-disable-next-line no-param-reassign
          log.view.url = log.view.url.replace(/email=[^&]*/, 'email=REDACTED');
        },
      });
      datadogRum.addRumGlobalContext('user', authUser);
    }

    if (authUser) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'Auth',
          type: 'Init',
          userId: authUser.sub,
        },
      });
    }

    if (authUserRoles) {
      window.usetifulTags = {
        language: i18n.language ? i18n.language.split('-')[0] : 'en',
        role: authUserRoles[0],
      };
      window.usetifulReady(window, document, window.usetifulUrl);
    }
  }

  yield all([auth(), url(), login()]);
}
