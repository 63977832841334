import React, { Suspense } from 'react';

import HotjarProvider from 'components/HotjarProvider';

import App from '../App';
import Loading from '../Loading';
import ReduxProvider from '../ReduxProvider';
import ThemeProvider from '../ThemeProvider';

const Root = () => (
  <ThemeProvider>
    <Suspense fallback={<Loading />}>
      <ReduxProvider>
        <App />
        <HotjarProvider />
      </ReduxProvider>
    </Suspense>
  </ThemeProvider>
);

Root.displayName = 'Root';

export default Root;
