import { getLocation } from 'connected-react-router';
import { createSelector } from 'reselect';
import { flow, get, getOr, toInteger } from 'lodash/fp';
import { parse, stringify } from 'qs';

import {
  PAGINATION_DEFAULT_PAGE,
  PAGINATION_DEFAULT_SIZE,
} from 'constants/general';

export const getSearch = createSelector(getLocation, get('search'));

export const parseSearch = (search) =>
  parse(search, {
    arrayLimit: 100, // will limit specifying indices in an array to a maximum index of 100. Any array members with an index of greater than 100 will instead be converted to an object with the index as the key. This is needed to handle cases when someone sent, for example, a[101] and it will take significant time to iterate over this huge array.
    ignoreQueryPrefix: true,
    // parse booleans
    decoder: (str, decoder, charset) => {
      const strWithoutPlus = str.replace(/\+/g, ' ');
      if (charset === 'iso-8859-1') {
        // unescape never throws, no try...catch needed:
        return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape);
      }

      const keywords = {
        true: true,
        false: false,
        null: null,
        undefined,
      };
      if (str in keywords) {
        return keywords[str];
      }

      // utf-8
      try {
        return decodeURIComponent(strWithoutPlus);
      } catch (e) {
        return strWithoutPlus;
      }
    },
  });

export const getQuery = createSelector(getSearch, parseSearch);

export const createSearch = (query) =>
  stringify(query, { addQueryPrefix: true });

export const getPaginationProps = (pageSize = PAGINATION_DEFAULT_SIZE) =>
  createSelector(
    getQuery,
    flow(getOr({}, 'page'), ({ page, size }) => ({
      page: page ? toInteger(page) : PAGINATION_DEFAULT_PAGE,
      size: size ? toInteger(size) : pageSize,
    })),
  );
