import { Auth0Error } from 'auth0-js';
import { replace } from 'connected-react-router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { getRedirect, reset, setAuth } from 'ducks/auth';
import { setError } from 'ducks/notification';

import routes from '../routes.json';

import auth from '../../auth';
import { locationShape } from '../../propTypes';

export const authRegExp = /access_token|id_token|error/;

let authenticating = false;

type IAuthProps = {
  location: Location;
};

const Auth = ({ location }: IAuthProps): JSX.Element | null => {
  const dispatch = useDispatch();

  const redirect = useSelector(getRedirect);

  const { hash } = location;
  const hasHash = authRegExp.test(hash);

  if (!hasHash) {
    return <Redirect to={routes.login} />;
  }

  if (!authenticating) {
    authenticating = true;
    throw new Promise((resolve) => {
      auth.parseHash((err: Auth0Error, authResult: unknown) => {
        if (authResult) {
          dispatch(setAuth(authResult));
          dispatch(replace(redirect));
        } else {
          dispatch(reset());
          dispatch(setError(err.errorDescription || err.toString()));
          dispatch(replace(routes.login));
        }

        authenticating = false;
      });
      resolve(true);
    });
  }

  return null;
};

Auth.propTypes = {
  location: locationShape.isRequired,
};

export default Auth;
