import { Theme } from '@material-ui/core/styles';
import {
  CreateCSSProperties,
  CSSProperties,
} from '@material-ui/styles/withStyles';
import { PaperClassKey, PaperProps } from '@material-ui/core/Paper';

export const muiPaperProps: PaperProps = {
  elevation: 0,
};

const muiPaper = (
  theme: Theme,
):
  | Partial<
      Record<
        PaperClassKey,
        | CSSProperties
        | CreateCSSProperties
        | ((props: Record<string, unknown>) => CreateCSSProperties)
      >
    >
  | undefined => ({
  root: {
    borderRadius: theme.shape.borderRadius * 2,
  },
});

export default muiPaper;
