import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { AlertTitle } from '@material-ui/lab';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Icon from 'components/atoms/Icon';

import useStylesToastr from './useStylesToastr';

const useStyles = makeStyles((theme) => ({
  ...useStylesToastr({ theme, backgroundColor: theme.palette.primary.main }),
}));

interface IInfo {
  children: {
    title?: string;
    description: string;
  };
}

const Info: React.FC<IInfo> = ({ children: { title, description } }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Alert
      classes={{
        root: classes.root,
        icon: classes.icon,
        message: classes.message,
      }}
      icon={<Icon icon={faInfoCircle} />}
      severity="info"
      variant="filled"
    >
      <AlertTitle>
        <Typography className={classes.text} variant="h4">
          {title || t('info', 'Info')}
        </Typography>
      </AlertTitle>
      <Typography className={classes.text} variant="h5">
        {description}
      </Typography>
    </Alert>
  );
};

export default Info;
