import { call, put, select, takeEvery } from 'redux-saga/effects';
import { flow, get, getOr } from 'lodash/fp';
import jwtDecode from 'jwt-decode';

import { EXTERNAL_USER, PRODUCER } from 'constants/general';

import { userInfo } from '../auth';

import { getAccessToken, getIdToken, reset, SET_AUTH } from './auth';

export const reducerKey = 'user';
export const USER_RULES_KEY = 'https://roles';

const SET = `${reducerKey}/SET`;

/**
 * UPDATE_PICTURE action type
 * @type {string}
 */
const UPDATE_PICTURE = `${reducerKey}/UPDATE_PICTURE`;

export const getUser = get([reducerKey]);
export const getUserRoles = flow(getUser, getOr([], USER_RULES_KEY));
/**
 * user picture selector
 * usage somewhere in the app
 * const pic = useSelector(getUserPicture) -> selects always the updated pic from user object
 */
export const getUserPicture = flow(getUser, getOr([], 'picture'));

/**
 * action to dispatch the update
 *
 * usage: dispatch(setUserPicture('the-url'))
 *
 * @param picture -> the picture url
 * @returns {{picture: string, type: string}}
 */
export const setUserPicture = (picture) => ({
  type: UPDATE_PICTURE,
  picture,
});

const initialState = null;

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SET:
      return payload;

    default:
      return state;
  }
};

export function* validateUserRole() {
  const idToken = yield select(getIdToken);

  if (idToken) {
    const user = jwtDecode(idToken);

    if (
      !(
        user[USER_RULES_KEY].includes(PRODUCER) ||
        user[USER_RULES_KEY].includes(EXTERNAL_USER)
      )
    ) {
      yield put(reset());
      throw Error('User does not have proper role to log in');
    }
  }
}

export function* refreshUserPictureSaga(user) {
  const accessToken = yield select(getAccessToken);

  if (accessToken) {
    const userData = yield call(userInfo, accessToken);

    if (userData) {
      yield put({
        type: SET,
        payload: {
          ...user,
          picture: userData.picture,
        },
      });
    }
  }
}

export function* userSaga() {
  const idToken = yield select(getIdToken);

  if (idToken) {
    const user = jwtDecode(idToken);

    yield put({
      type: SET,
      payload: user,
    });

    yield refreshUserPictureSaga(user);
  }
}

/**
 * user picture saga - combines the existing user object from state with the new picture url
 *
 * @param picture
 * @returns {Generator<<"PUT", PutEffectDescriptor<{payload: {[p: string]: *}, type: string}>>|<"SELECT", SelectEffectDescriptor>, void, *>}
 */

function* updateUserPictureSaga({ picture }) {
  // gets the user form state
  const user = yield select(getUser);

  /**
   * combiner
   * put is the redux saga effect what takes a actionType with a payload and write(put) it to the state
   */
  yield put({
    type: SET,
    payload: {
      ...user,
      picture,
    },
  });
}

export function* saga() {
  yield userSaga();

  // that redux know what to do if UPDATE_PICTURE triggered
  yield takeEvery(UPDATE_PICTURE, updateUserPictureSaga);
  yield takeEvery(SET_AUTH, userSaga);
}
