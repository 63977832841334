/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';

import { MONTH, WEEK } from './constants/dateRanges';

export const locationShape = PropTypes.shape({
  hash: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
});

export const dateRangeType = PropTypes.oneOf([WEEK, MONTH]);
