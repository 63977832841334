import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import CenterContainer from '../CenterContainer';
import HeadTitle from '../HeadTitle';

const Exception = ({ description, error, title }) => {
  const { t } = useTranslation();

  return (
    <>
      <HeadTitle>{title || t('Error')}</HeadTitle>
      <CenterContainer>
        <Typography gutterBottom variant="h5">
          {error instanceof Error ? error.toString() : error}
        </Typography>
        {description && <Typography variant="body1">{description}</Typography>}
      </CenterContainer>
    </>
  );
};

Exception.propTypes = {
  description: PropTypes.node,
  error: PropTypes.oneOfType([PropTypes.instanceOf(Error), PropTypes.node])
    .isRequired,
  title: PropTypes.node,
};

Exception.defaultProps = {
  description: null,
  title: undefined,
};

export default Exception;
