import { takeEvery } from 'redux-saga/effects';

import { userSaga, validateUserRole } from './user';
import { SET_AUTH_LOGIN, setAuthSaga } from './auth';

export const setLogin = (payload) => ({ type: SET_AUTH_LOGIN, payload });

function* setLoginSaga() {
  yield validateUserRole();
  yield setAuthSaga();
  yield userSaga();
}

export function* saga() {
  yield takeEvery(SET_AUTH_LOGIN, setLoginSaga);
}
