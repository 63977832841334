import { useEffect } from 'react';

const useThirdPartyScript = (
  url: string,
  removeOnUnMount = true,
  load = true,
): void => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.defer = true;

    if (load) {
      document.body.appendChild(script);
    }

    return () => {
      if (load && removeOnUnMount) {
        document.body.removeChild(script);
      }
    };
  }, [url, load, removeOnUnMount]);
};

export default useThirdPartyScript;
