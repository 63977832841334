import axios from 'axios';
import { stringify } from 'qs';
import { get } from 'lodash/fp';

const instance = axios.create({ paramsSerializer: stringify });

let api;
let authorization;

export const initRequest = ({ api: apiOption, accessToken }) => {
  api = apiOption;
  authorization = `Bearer ${accessToken}`;
};

const request = (config) => instance(config).then(get('data'));

export const download = ({ headers, ...config }) =>
  request({
    baseURL: api.download,
    headers: { ...headers, authorization },
    ...config,
  });
