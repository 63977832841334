import React from 'react';
import { MuiThemeProvider } from '@material-ui/core';

import GlobalCss from './GlobalCss';
import themeOptions from './themeOptions';

interface IThemeProviderProps {
  children: React.ReactElement;
}

const ThemeProvider: React.FC<IThemeProviderProps> = ({ children }) => (
  <MuiThemeProvider theme={themeOptions}>
    <GlobalCss />
    {children}
  </MuiThemeProvider>
);

export default ThemeProvider;
