import auth0 from 'auth0-js';

import routes from './routes/routes.json';

export const connection = 'Username-Password-Authentication';

let clientID = 'SILtWKc3zgTEaCr92KiKUdXmjVgjoNdQ';

if (window.location.host === 'app.sentryc.com') {
  clientID = 'ZfZ44n0FfvcunzFLwvN9LztRx8Fc1YK4';
}

const auth = new auth0.WebAuth({
  clientID,
  audience: 'aragog.sentryc.com',
  domain: 'sentryc.eu.auth0.com',
  redirectUri: `${window.location.origin}${routes.auth}?cache=${CACHE}`,
  responseType: 'token id_token',
  scope: 'openid profile email app_metadata roles',
});

export const userInfo = (token) =>
  new Promise((resolve) => {
    auth.client.userInfo(token, (err, usr) => {
      resolve(usr);
    });
  });

export default auth;

const getBaseDomain = (domain) => domain.split('.').slice(-2).join('.');

export const sameOrigin =
  getBaseDomain(auth.baseOptions.domain) ===
  getBaseDomain(window.location.hostname);
