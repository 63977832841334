import { Theme } from '@material-ui/core/styles';
import {
  CreateCSSProperties,
  CSSProperties,
} from '@material-ui/styles/withStyles';
import { TooltipClassKey } from '@material-ui/core/Tooltip';

const muiCheckbox = (
  theme: Theme,
):
  | Partial<
      Record<
        TooltipClassKey,
        | CSSProperties
        | CreateCSSProperties
        | ((props: Record<string, unknown>) => CreateCSSProperties)
      >
    >
  | undefined => ({
  tooltip: {
    backgroundColor: 'black',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500,
    padding: theme.spacing(1, 2),
  },
});

export default muiCheckbox;
