import { push } from 'connected-react-router';
import { cps, delay, put, select, spawn, takeEvery } from 'redux-saga/effects';
import { defaultMemoize } from 'reselect';
import jwtDecode from 'jwt-decode';
import { flow, get, getOr, multiply } from 'lodash/fp';
import TagManager from 'react-gtm-module';

import { getApi } from 'ducks/config';

import { initRequest } from '../api/request';
import auth, { sameOrigin } from '../auth';
import routes from '../routes/routes.json';

export const reducerKey = 'auth';

export const LOG_OUT = `${reducerKey}/LOG_OUT`;
export const RESET = `${reducerKey}/RESET`;
export const SET_AUTH = `${reducerKey}/SET_AUTH`;
export const SET_AUTH_LOGIN = `${reducerKey}/SET_AUTH_LOGIN`;
export const SET_REDIRECT = `${reducerKey}/SET_REDIRECT`;

export const logOut = () => ({ type: LOG_OUT });
export const reset = () => ({ type: RESET });
export const setAuth = (payload) => ({ type: SET_AUTH, payload });
export const setRedirect = (payload) => ({ type: SET_REDIRECT, payload });

export const getAccessTokenExpiresAt = get([reducerKey, 'expiresAt']);
export const getIdToken = get([reducerKey, 'idToken']);
export const getAccessToken = get([reducerKey, 'accessToken']);
export const getRedirect = getOr(routes.home, [reducerKey, 'redirect']);

const getIdTokenExpiresAt = defaultMemoize(
  flow(jwtDecode, get('exp'), multiply(1000)),
);

export const getRoles = defaultMemoize(flow(jwtDecode, get('https://roles')));

export const getAuthorized = (state) => {
  const now = Date.now();

  const accessTokenExpiresAt = getAccessTokenExpiresAt(state);

  if (accessTokenExpiresAt <= now) {
    return false;
  }

  const idToken = getIdToken(state);

  if (idToken) {
    return getIdTokenExpiresAt(idToken) > now;
  }

  return false;
};

const initialState = null;

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case RESET:
      return initialState;

    case SET_AUTH_LOGIN:
    case SET_AUTH: {
      const { expiresIn, idToken, accessToken } = payload;
      const expiresAt = Date.now() + expiresIn * 1000;

      return {
        expiresAt,
        idToken,
        accessToken,
      };
    }

    case SET_REDIRECT:
      return {
        redirect: payload,
      };

    default:
      return state;
  }
};

let tokenRenewalTask = null;

export const hasRenewal = () => Boolean(tokenRenewalTask);

function* renewToken() {
  const payload = yield cps([auth, auth.checkSession], {});
  yield put(setAuth(payload));
}

function* scheduleRenewal() {
  const now = Date.now();

  const accessTokenExpiresAt = yield select(getAccessTokenExpiresAt);

  yield delay(accessTokenExpiresAt - now);

  try {
    yield renewToken();
  } catch (error) {
    yield put(reset());
    yield put(push(routes.login));
  }
}

function* initRequestSaga() {
  const api = yield select(getApi);
  const accessToken = yield select(getAccessToken);

  initRequest({ api, accessToken });
}

export function* setAuthSaga() {
  if (sameOrigin) {
    tokenRenewalTask = yield spawn(scheduleRenewal);
  }
  yield initRequestSaga();
}

function* logOutSaga() {
  if (sameOrigin) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Auth',
        type: 'Logout',
      },
    });

    auth.logout({
      returnTo: `${window.location.origin}${routes.login}`,
    });
  } else {
    yield put(push(routes.login));
    yield put(reset());
  }
}

export function* saga() {
  const authorized = yield select(getAuthorized);

  if (!sameOrigin && authorized) {
    yield setAuthSaga();
  }

  yield takeEvery(LOG_OUT, logOutSaga);
  yield takeEvery(SET_AUTH, setAuthSaga);
}
