import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { useSelector } from 'react-redux';

import { getHotjarId, getHotjarSnippetVersion } from 'ducks/config';

const HotjarProvider = () => {
  const hjid = useSelector(getHotjarId);
  const hjsv = useSelector(getHotjarSnippetVersion);

  useEffect(() => {
    if (hjid && hjsv) {
      hotjar.initialize(hjid, hjsv);
    }
  }, [hjid, hjsv]);

  return null;
};

export default HotjarProvider;
