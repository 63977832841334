import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Features } from 'react-enable';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

import useThirdPartyScript from 'hooks/useThirdPartyScript';
import Auth from 'routes/Auth';
import routes from 'routes/routes.json';
import { FEATURES_ALL, FEATURES_ENABLED } from 'constants/general';
import { useLocalizer } from 'hooks/config';
import useWhitelabelInfo from 'hooks/useWhitelabelInfo';

import ErrorBoundary from '../ErrorBoundary';
import ToastProvider from '../ToastProvider';

import Notification from './Notification';

const Welcome = React.lazy(
  () => import(/* webpackChunkName: "welcome" */ '../../routes/Welcome'),
);

const Home = React.lazy(
  () => import(/* webpackChunkName: "home" */ '../../routes/Admin'),
);

const App: React.FC = () => {
  useThirdPartyScript(
    'https://consent.cookiebot.com/uc.js?cbid=ee3d20eb-8bc8-482b-be6a-ed6025ca6335',
  );
  const { favicon } = useWhitelabelInfo();

  React.useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      // @ts-ignore
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    // @ts-ignore
    link.href = favicon;
  }, []);

  return (
    <Features
      consoleOverride
      defaultEnabled={FEATURES_ENABLED}
      features={FEATURES_ALL}
    >
      <ErrorBoundary>
        <ToastProvider>
          <Switch>
            <Route component={Auth} exact path={routes.auth} />
            <Route
              component={Welcome}
              exact
              path={[routes.login, routes.reset]}
            />
            <Route component={Home} path={routes.home} />
            <Redirect to={routes.login} />
          </Switch>

          <Notification />
        </ToastProvider>
      </ErrorBoundary>
    </Features>
  );
};

export default withLDProvider({
  clientSideID: LAUNCH_DARKLY_KEY,
})(App);
