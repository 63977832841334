import React from 'react';
import { CssBaseline, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(
  {
    '@global': {
      html: {
        height: '100%',
      },
      body: {
        height: '100%',
      },
      '#root': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      },
    },
  },
  { name: 'GlobalCss' },
);

const GlobalCss = () => useStyles() && <CssBaseline />;

export default GlobalCss;
