import { get } from 'lodash/fp';

export const reducerKey = 'settings';

const UPDATE = `${reducerKey}/UPDATE`;

export const updateSettings = (payload) => ({ type: UPDATE, payload });

export const getSettings = get([reducerKey]);

const initialState = {};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case UPDATE:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};
