import { Theme } from '@material-ui/core/styles';
import {
  CreateCSSProperties,
  CSSProperties,
} from '@material-ui/styles/withStyles';
import { ButtonClassKey, ButtonTypeMap } from '@material-ui/core/Button';
import { OverrideProps } from '@material-ui/core/OverridableComponent';

export const muiButtonProps:
  | Partial<
      OverrideProps<ButtonTypeMap<Record<string, unknown>, 'button'>, 'button'>
    >
  | undefined = {
  variant: 'contained',
  color: 'primary',
};

const muiButton = (
  theme: Theme,
):
  | Partial<
      Record<
        ButtonClassKey,
        | CSSProperties
        | CreateCSSProperties
        | ((props: Record<string, unknown>) => CreateCSSProperties)
      >
    >
  | undefined => ({
  root: {
    boxShadow: 'none',
    borderRadius: 20,
    fontSize: theme.typography.pxToRem(14),
    height: 40,
    textTransform: 'inherit',
  },
  sizeSmall: {
    height: 30,
    borderRadius: 15,
    fontSize: theme.typography.pxToRem(12),
    padding: theme.spacing(0, 2),
  },
  startIcon: {
    marginRight: theme.spacing(1),
    marginLeft: 0,
  },
  endIcon: {
    marginLeft: theme.spacing(1),
    marginRight: 0,
  },
  sizeLarge: {
    height: 48,
    borderRadius: 23,
    fontSize: theme.typography.pxToRem(18),
    padding: theme.spacing(0, 5),
  },
  iconSizeSmall: {
    '&.MuiButton-iconSizeLarge > svg': {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  iconSizeMedium: {
    '&.MuiButton-iconSizeLarge > svg': {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  iconSizeLarge: {
    '&.MuiButton-iconSizeLarge > svg': {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  contained: {
    boxShadow: 'none',
    padding: theme.spacing(0, 3),
    '&.Mui-disabled': {
      opacity: 0.4,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    '&:hover': {
      // some material-ui defualt override this when @media(hover: none)
      boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.35)',
      '@media(hover: none)': {
        boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.35)',
      },
    },
  },
  containedPrimary: {
    color: '#ffffff',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  containedSizeLarge: {
    '&:hover': {
      boxShadow: '0px 10px 25px rgba(0, 0, 0, 0.35)',
      backgroundColor: theme.palette.primary.main,
    },
  },
  containedSizeSmall: {
    '&:hover': {
      boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.35)',
      backgroundColor: theme.palette.primary.main,
    },
  },
  outlined: {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    padding: theme.spacing(0, 3),
    '&.Mui-disabled': {
      borderColor: theme.palette.secondary[200],
      color: theme.palette.secondary[400],
    },
    '&:hover': {
      boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.35)',
      backgroundColor: 'transparent',
      color: theme.palette.secondary.main,
      borderColor: 'transparent',
    },
  },
  outlinedSecondary: {
    '&:hover': {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
    },
  },
  outlinedPrimary: {
    '&:hover': {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
    },
  },
  outlinedSizeLarge: {
    '&:hover': {
      boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.35)',
      backgroundColor: 'transparent',
      color: theme.palette.secondary.main,
      borderColor: 'transparent',
    },
  },
  outlinedSizeSmall: {
    '&:hover': {
      boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.35)',
      backgroundColor: 'transparent',
      color: theme.palette.secondary.main,
      borderColor: 'transparent',
    },
  },
  text: {
    borderRadius: 0,
    '& > span': {
      borderBottom: `1px solid transparent`,
      transition: 'all .3s',
    },
    '&.Mui-disabled': {
      color: theme.palette.secondary[400],
    },
  },
  textPrimary: {
    '&:hover': {
      backgroundColor: 'transparent',
      '& > span': {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        '@media (hover: none)': {
          borderBottom: `1px solid transparent`,
        },
      },
    },
  },
  textSecondary: {
    '&:hover': {
      backgroundColor: 'transparent',
      '& > span': {
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
        '@media (hover: none)': {
          borderBottom: `1px solid transparent`,
        },
      },
    },
  },
});

export default muiButton;
