import * as React from 'react';

import Exception from '../Exception';

interface IState {
  error?: Error;
}

export default class ErrorBoundary extends React.PureComponent<
  Record<string, unknown>,
  IState
> {
  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  constructor(props: Readonly<Record<string, unknown>>) {
    super(props);

    this.state = {};
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    return error ? <Exception error={error} /> : children;
  }
}
