import { Theme } from '@material-ui/core/styles';
import {
  CreateCSSProperties,
  CSSProperties,
} from '@material-ui/styles/withStyles';
import { TableClassKey } from '@material-ui/core/Table';

const muiTable = (
  theme: Theme,
):
  | Partial<
      Record<
        TableClassKey,
        | CSSProperties
        | CreateCSSProperties
        | ((props: Record<string, unknown>) => CreateCSSProperties)
      >
    >
  | undefined => ({
  root: {
    '& .MuiTableCell-root.MuiTableCell-head': {
      borderBottom: `1px solid ${theme.palette.secondary[800]}`,
      padding: theme.spacing(1.5, 1),
    },
    '& .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root': {
      borderBottom: 0,
      padding: theme.spacing(1.5, 1),
    },
    '& .MuiTableBody-root .MuiTableRow-root:nth-of-type(even)': {
      backgroundColor: theme.palette.secondary[100],
    },
  },
});

export default muiTable;
