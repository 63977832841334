import { Theme } from '@material-ui/core';

const useStylesToastr = ({
  theme,
  backgroundColor,
}: {
  theme: Theme;
  backgroundColor: string;
}) => ({
  icon: {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    padding: theme.spacing(0, 1.5),
    display: 'flex',
    alignItems: 'center',
    backgroundColor,
    marginRight: theme.spacing(2),
    minWidth: 44,
  },
  root: {
    borderRadius: 8,
    minHeight: 83,
    width: '100%',
    padding: theme.spacing(0, 3, 0, 0),
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
  },
  message: {
    flexBasis: '100%',
  },
  text: {
    color: theme.palette.common.black,
  },
});

export default useStylesToastr;
