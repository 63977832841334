// @ts-nocheck
import React from 'react';
import { makeStyles } from '@material-ui/core';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const useStyles = makeStyles({
  wrapper: {
    fontSize: '1.13rem',
  },
});

interface IIcon extends Omit<FontAwesomeIconProps, 'icon'> {
  cursor?: string;
  disabled?: boolean;
  icon: IconProp | IconDefinition;
}

const Icon: React.FC<IIcon> = (props) => {
  const classes = useStyles();

  return (
    <span className={classes.wrapper}>
      <FontAwesomeIcon {...props} />
    </span>
  );
};

export default Icon;
