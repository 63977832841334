import { Theme } from '@material-ui/core/styles';
import {
  CreateCSSProperties,
  CSSProperties,
} from '@material-ui/styles/withStyles';
import { AutocompleteClassKey } from '@material-ui/lab';

export const boxShadow = `0px 4px 15px rgba(0, 0, 0, 0.2)`;

const muiAutocomplete = ({
  palette,
  spacing,
  typography,
}: Theme):
  | Partial<
      Record<
        AutocompleteClassKey,
        | CSSProperties
        | CreateCSSProperties
        | ((props: Record<string, unknown>) => CreateCSSProperties)
      >
    >
  | undefined => ({
  root: {
    '&.MuiAutocomplete-hasPopupIcon': {
      '&.MuiAutocomplete-hasClearIcon': {
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
          paddingRight: spacing(3),
        },
        '& .MuiAutocomplete-inputRoot': {
          paddingRight: 0,
        },
      },
    },
    '&.MuiAutocomplete-hasPopupIcon[name="producerId"]': {
      '&.MuiAutocomplete-hasClearIcon': {
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
          paddingRight: spacing(3),
        },
        '& .MuiAutocomplete-inputRoot': {
          paddingRight: 0,
        },
      },
    },
    '&.MuiAutocomplete-hasPopupIcon[name="productIds"]': {
      '&.MuiAutocomplete-hasClearIcon': {
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
          paddingRight: spacing(7),
        },
        '& .MuiAutocomplete-inputRoot': {
          paddingRight: 0,
        },
      },
    },
    '&.MuiAutocomplete-hasPopupIcon[name="marketplaceIds"]': {
      '&.MuiAutocomplete-hasClearIcon': {
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
          paddingRight: spacing(7),
        },
        '& .MuiAutocomplete-inputRoot': {
          paddingRight: 0,
        },
      },
    },
    '&.MuiAutocomplete-hasPopupIcon[name="source"]': {
      '&.MuiAutocomplete-hasClearIcon': {
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
          paddingRight: spacing(3),
        },
        '& .MuiAutocomplete-inputRoot': {
          paddingRight: 0,
        },
      },
    },
    '&.MuiAutocomplete-hasPopupIcon[name="blockReasonType"]': {
      '&.MuiAutocomplete-hasClearIcon': {
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
          paddingRight: spacing(3),
        },
        '& .MuiAutocomplete-inputRoot': {
          paddingRight: 0,
        },
      },
    },
    '&.MuiAutocomplete-hasPopupIcon[name="testPurchaseState"]': {
      '&.MuiAutocomplete-hasClearIcon': {
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
          paddingRight: spacing(3),
        },
        '& .MuiAutocomplete-inputRoot': {
          paddingRight: 0,
        },
      },
    },
    '& .MuiChip-root': {
      backgroundColor: palette.common.white,
      '& .MuiChip-label': {
        fontSize: typography.pxToRem(14),
        paddingTop: 0,
        paddingBottom: 0,
      },
      '& .MuiChip-deleteIcon': {
        color: 'inherit',
        margin: 0,
      },
    },

    '& .MuiAutocomplete-tagSizeSmall': {
      marginRight: spacing(1.5),
    },
  },
  inputRoot: {
    '&.Mui-focused': {
      boxShadow,
    },
    '&[class*="MuiOutlinedInput-root"]': {
      '& .MuiAutocomplete-input:first-child': {
        paddingLeft: 0,
      },
      '&[class*="MuiOutlinedInput-marginDense"]': {
        paddingLeft: spacing(1.5),
        paddingRight: spacing(3),
        '& .MuiAutocomplete-input': {
          paddingLeft: 0,
        },
      },
    },
    '& span.MuiAutocomplete-tag': {
      backgroundColor: palette.primary.main,
      color: palette.common.white,
      borderRadius: spacing(1.5),
      padding: [[0, 4]],
      fontSize: typography.pxToRem(12),
      marginRight: spacing(1.5),
    },
  },
  endAdornment: {
    right: '0px !important',
    paddingRight: spacing(1),
  },
  option: {
    ...typography.body2,
    lineHeight: typography.pxToRem(16),
    paddingTop: spacing(1.5),
    paddingRight: spacing(1.75),
    paddingBottom: spacing(1.5),
    paddingLeft: spacing(1.75),

    '&[data-focus="true"]': {
      backgroundColor: palette.primary[50],
    },
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: palette.primary[100],
      },
    },
  },
  popper: {
    left: '1px !important', // popper position is calculated incorrectly somewhere???

    '&[x-placement="top"]': {
      '& .MuiAutocomplete-paper': {
        borderBottom: `1px solid ${palette.secondary[200]}`,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,

        '&.MuiPaper-elevation0, &.MuiPaper-elevation1': {
          boxShadow: '0px -4px 15px rgba(0, 0, 0, 0.2)',
        },
      },
    },
    '&[x-placement="bottom"]': {
      '& .MuiAutocomplete-paper': {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        marginTop: spacing(0.5),

        '&.MuiPaper-elevation0, &.MuiPaper-elevation1': {
          boxShadow,
        },
      },
    },
    '& .MuiButton-textPrimary': {
      borderBottom: 'none !important',
      '&:hover > span': {
        borderBottom: 'none',
      },
    },
  },
  paper: {
    margin: 0,
  },
  listbox: {
    padding: 0,
  },
});

export default muiAutocomplete;
