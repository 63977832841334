import { CSSProperties, useEffect, useState } from 'react';

import { useLocalizer } from 'hooks/config';

export enum WhitelabelTypes {
  Sentryc = 'sentryc',
  AWA = 'awa',
}

export type WhitelabelStyles = {
  [key: string]: CSSProperties;
};

export type WhitelabelDetails = {
  id: WhitelabelTypes;
  name: string;
  nameLong: string;
  styles: WhitelabelStyles;
  domains: string[];
};

export type WhitelabelInfo = WhitelabelDetails & {
  emailTakedown: string;
  helpCenterEnabled: boolean;
  footerEnabled: boolean;
  hostname: string;
  isDefault: boolean;
  isSentryc: boolean;
  logo: string;
  logoWhite: string;
  logoHeader: string;
  bgCover: string;
  favicon: string;
};

const WHITELABELS: { [key: string]: WhitelabelDetails } = {
  'sentryc.com': {
    id: WhitelabelTypes.Sentryc,
    name: 'Sentryc',
    nameLong: 'Sentryc GmbH',
    styles: {},
    domains: [
      'app.sentryc.com',
      'app.staging.sentryc.com',
      'app.demo.sentryc.com',
    ],
  },
  'awadotkeeper.com': {
    id: WhitelabelTypes.AWA,
    name: 'AWA',
    nameLong: 'AWA Dotkeeper GmbH',
    styles: {
      logoWelcome: {
        width: '8em',
      },
    },
    domains: [
      'bp.awadotkeeper.com',
      'staging.bp.awadotkeeper.com',
      'demo.bp.awadotkeeper.com',
    ],
  },
};

const featureDeploySuffix = '.branches.protego.sentryc.com';
export const defaultHostname = 'app.sentryc.com';

const getWhitelabelInfo = (
  hostname: string,
  localizer: string,
): WhitelabelInfo => {
  try {
    const selectedWhitelabel: WhitelabelDetails =
      Object.values(WHITELABELS).find(({ domains }) =>
        domains.includes(hostname),
      ) || WHITELABELS[defaultHostname];
    const { id, ...whitelabel } = selectedWhitelabel;

    return {
      id,
      ...whitelabel,
      emailTakedown: 'takedown@sentryc.com',
      helpCenterEnabled: id === WhitelabelTypes.Sentryc,
      footerEnabled: id === WhitelabelTypes.Sentryc,
      hostname,
      isDefault: id === WhitelabelTypes.Sentryc,
      isSentryc: id === WhitelabelTypes.Sentryc,
      logo: `${localizer}/img/${id}/logo.svg`,
      logoWhite: `${localizer}/img/${id}/logo_white.svg`,
      logoHeader: `${localizer}/img/${id}/logo.png`,
      bgCover: `${localizer}/img/${id}/welcome.png`,
      favicon: `${localizer}/img/${id}/favicon.ico`,
    };
  } catch (error) {
    throw Error(`Whitelabel not found for hostname ${hostname}`);
  }
};

const useWhitelabelInfo = (): WhitelabelInfo => {
  const hostname =
    window.location.hostname === 'localhost' ||
    window.location.hostname.includes(featureDeploySuffix)
      ? defaultHostname
      : window.location.hostname;
  const localizer = useLocalizer();
  const [info, setInfo] = useState<WhitelabelInfo>(
    getWhitelabelInfo(hostname, localizer),
  );

  useEffect(() => {
    setInfo(getWhitelabelInfo(hostname, localizer));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hostname]);

  return info;
};

export default useWhitelabelInfo;
